<template>
  <div id="conorderbox">
    <div class="hadheight"></div>
    <Header />
    <div class="conorderbox-sec1">
        <div class="basecont">
            <div class="con_nav">
                <router-link to="/my/myorder" class="returngt">
                    <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                    <span>返回订单</span>
                </router-link>
            </div>
            <div class="cont">
                <div class="logo">
                    <router-link to="/">
                        <img src="../../assets/images/gouwuliuimg1.png" alt="">
                    </router-link>
                </div>
            </div> 
        </div>
    </div>
    <div class="conorderbox-sec2 base150">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="conor_tail" v-if="orderTail.length!=0">
                        <div class="conor_tit basefont30">订单跟踪</div>
                        <div class="fztit">
                            <div class="tit">顺丰快递</div>
                            <div class="hao">{{orderTail.mailNo}}</div>
                        </div>
                        <div class="fzlist">
                            <template v-for="(item, index) in orderRoutesReverse">
                                <div 
                                :class="{f_li:true ,on: index==0}" 
                                :key="index">
                                    <div class="fzzi">
                                        <div class="f_font">
                                            <div class="f_ti1">{{item.remark}}</div>
                                            <div class="time">{{item.acceptTime.substring(0,16)}}</div>
                                        </div>
                                        <div class="text">
                                            {{item.remark}}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="conor_form">
                        <div class="conor_tit basefont30">收货人信息</div>
                        <!-- <template v-if="baocun">
                            <form action="" class="form">
                                <div class="item">
                                    <input type="text" v-model="form.name" name="name" placeholder="* 收货人">
                                </div>
                                <div class="item">
                                    <input type="text" v-model="form.tel" name="tel" placeholder="* 手机号码">
                                </div>
                                <div class="f_jut">
                                    <div class="item">
                                        <select name="sheng" v-model="form.sheng" >
                                            <option value="广东省">广东省</option>
                                            <option value="广西省">广西省</option>
                                        </select>
                                    </div>
                                    <div class="item">
                                        <select name="sheng" v-model="form.shi" >
                                            <option value="深圳市">深圳市</option>
                                        </select>
                                    </div>
                                    <div class="item">
                                        <select name="sheng" v-model="form.qu" >
                                            <option value="龙华区">龙华区</option>
                                            <option value="陆河县">陆河县</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="item">
                                    <input type="text" v-model="form.adds" name="adds" placeholder="* 详细地址">
                                </div>
                                <button @click.prevent="addressSubmit()" class="hm-but f_but">
                                    <span>保存地址</span>
                                    <i>
                                        <img src="../../assets/images/jiantouyo1.png" >
                                        <img src="../../assets/images/jiantouyo2.png" >
                                    </i>
                                </button>
                            </form>
                        </template> -->
                        <template v-if="baocun==true?false:true">
                            <div class="f_address">
                                <!-- <template v-for="(item,index) in addressdizhi">
                                    <div :class="{item:true,on:addsele==index}"  :key="index">
                                        <a href="javascript:;" class="list" @click="addsele=index">
                                            <div class="tit">
                                                <strong>{{item.name}}</strong>
                                                <strong>{{item.tel}}</strong>
                                            </div>
                                            <div class="text">
                                                {{item.adr}}
                                            </div>
                                            <div class="but">
                                                <div class="f_but" @click.stop="addressRemove(index)">删除</div>
                                                <div class="f_but" @click.stop="addressEdit(index)">编辑</div>
                                            </div>
                                        </a>
                                    </div>
                                </template>  -->
                                <div class="item on">
                                        <a href="javascript:;" class="list">
                                            <div class="tit">
                                                <strong>{{myOrderParams.consignee}}</strong>
                                                <strong>{{myOrderParams.mobile.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2")}}</strong>
                                            </div>
                                            <div class="text">
                                                {{myOrderParams.province}} {{myOrderParams.district}} {{myOrderParams.city}}
                                            </div>
                                        </a>
                                </div>
                                
                                
                                <!-- <div class="item active">
                                    <a href="javascript:;" @click="baocun=true" class="list">
                                        <div class="f_text">+ 新增地址</div>
                                    </a>
                                </div> -->
                            </div>
                        </template>
                    </div>
                    <div class="conor_message">
                        <div class="conor_tit">
                            <div class="tit basefont30">确认商品信息</div>
                            <div class="jian">{{myOrderParams.orderItemInfoList.length}}件商品</div>
                        </div>
                        <div class="f_jut">
                            <div class="item" v-for="(item,index) in myOrderParams.orderItemInfoList" :key="index">
                                <div class="img">
                                    <img :src="item.skuDefaultImg" :alt="item.skuName">
                                </div>
                                <div class="font">
                                    <div class="tit">{{item.skuName}}</div>
                                    <div class="text">
                                        <p>{{item.skuDesc}}</p>
                                        <p>零售价：{{item.retailPrice}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gt">
                    <div class="f_nei">
                        <div class="tit">订单详情</div>
                        <div class="mess">
                            <div class="li">
                                <span>商品总价</span>
                                <span>¥ {{myOrderParams.orderPrice}}</span>
                            </div>
                            <div class="li">
                                <span>优惠券</span>
                                <span>¥ {{myOrderParams.couponPrice}}</span>
                            </div>
                            <div class="li">
                                <span>消费税</span>
                                <span>¥ {{myOrderParams.exciseTax}}</span>
                            </div>
                            <!-- <div class="li">
                                <span>运费</span>
                                <span>¥ {{myOrderParams.couponPrice}}</span>
                            </div> -->
                        </div>
                        <div class="zong">
                            <span>总计</span>
                            <div class="jia">¥ {{myOrderParams.actualPrice}}</div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Footer
  },
  data(){
    return {
      adFade:false,
      addsele: 2000,
      xie: false,
      form:{ //表单内容
        name: "",
        tel: "",
        sheng: "广东省",
        shi: "深圳市",
        qu: "龙华区",
        adds: ""
      },
      form2:{ //编辑收货地址
        name: "",
        tel: "",
        sheng: "广东省",
        shi: "深圳市",
        qu: "龙华区",
        adds: ""
      },
      baocun: false,
      ordermessage:"",
      shopmess:[
          {
              url: require('../../assets/images/flunkuotuimgf1.jpg'),
              title: "【 BASE KIT 】 PRO DAMPER UCF1",
              text:"单价：¥ 3400    数量：1    尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21    孔距BP(mm)&孔数：9.5J×21    颜色：黑色ET/Offset (mm)：9.5J×21    中心孔径(mm)：9.5J×21    里间距Rear Spacing(mm)：9.5J×21   ",
              id: 1
          },
          {
              url: require('../../assets/images/flunkuotuimgf1.jpg'),
              title: "【 BASE KIT 】 PRO DAMPER UCF1",
              text:"单价：¥ 3400    数量：1    尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21    孔距BP(mm)&孔数：9.5J×21    颜色：黑色ET/Offset (mm)：9.5J×21    中心孔径(mm)：9.5J×21    里间距Rear Spacing(mm)：9.5J×21   ",
              id: 2
          }
      ],
      addressdizhi:[
          {
              name: "赵四",
              texl: "15812612321",
              adr: "广东  深圳市  罗湖区",
              id: 1,
              mo: false
          }
      ],
      orderTail: [],
      orderRoutesReverse:[],
    }
  },
  
  beforeCreate(){
   
  },
  created(){
       // 物流查询
        this.$axios.post('/api2/portal/auth/order/sf/listOrderRoute',{
            orderId:this.myOrderParams.id
        }).then( res => {
            // console.log(res); 
            if(res.code == "000000"){
                this.orderTail = res.data[0]; 
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
        } ).catch(error => {
            console.log(error);
        }); 
  },
  mounted(){
    
  },
  computed:{
    myOrderParams(){ //获取订单数据
        return JSON.parse(window.localStorage.getItem("orderMessage"));
    },
  },
  watch:{
    orderTail(qi,hou){  //翻转接口 
        this.orderRoutesReverse = qi.routes.reverse();
    }
  },
  methods: {
    proaltShow(){
        $(".proinfoAlt").fadeIn();
    },
    addressRemove:function(index){ //删除地址
        this.addressdizhi.splice(index,1);
    },
    returncli:function(){  //返回上一页
        this.$router.back();
    },
    telCode:function(){ //手机号码判断
        var re = /^1\d{10}$/;
        if(this.form.tel==""){
            this.$message({
                message: '手机号码不能为空',
                type: 'warning',
            });
            return false;
        }
        if(re.test(this.form.tel)){
            return true;
        }else{
            this.$message({
                message: '手机号码输入错误',
                type: 'warning',
            });
            return false;
        }
    },
    telCode2:function(){ //手机号码判断
        var re = /^1\d{10}$/;
        if(this.form2.tel==""){
            this.$message({
                message: '手机号码不能为空',
                type: 'warning',
            });
            return false;
        }
        if(re.test(this.form2.tel)){
            return true;
        }else{
            this.$message({
                message: '手机号码输入错误',
                type: 'warning',
            });
            return false;
        }
    },
    addressSubmit:function(){  //保存地址
        if(this.form.name==""){
            this.$message({
                type: "info",
                message: "收货人不能为空！"
            });
            return false;
        }
        if(!this.telCode()){
            return false;
        }
        this.baocun=false;
    },
    addressSubmit2:function(){  //编辑收货地址
        if(this.form2.name==""){
            this.$message({
                type: "info",
                message: "收货人不能为空！"
            });
            return false;
        }
        if(!this.telCode2()){
            return false;
        }
        this.adFade=false
    },
    subCon:function(){ //提交订单
        if(this.xie!=true){
            this.$message({
                type: "info",
                message: "请查看并勾选隐私政策和注册协议！"
            });
            return false;
        }
        this.$router.push({path:"/payment"});
    },
    addressEdit:function(){
        this.adFade=true;
    },
    
  }
}
</script>
<style lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>